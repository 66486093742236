import React from 'react';
import './style.css';

import phinch1 from '../images/phinch1.png';
import phinch2 from '../images/phinch2.png';
import github from '../images/github.png'; 
import slack from '../images/slack.png';
import googlegroups from '../images/googlegroups.png';
import phinchblog from '../images/phinchblog.png';

const communities = [
  {
    name: 'GitHub',
    link: 'https://github.com/PhinchApp/Phinch',
    columns: [
      { type: 'logo', value: <img src={github} alt='github' /> },
      { type: 'Phinch2', value: (
          <a
            href='https://github.com/PhinchApp/Phinch'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={phinch2} alt='phinch2' />
          </a>
        )
      },
      { type: 'notes', value: <p>Bug reporting and software documentation</p> },
      { type: 'Phinch1', value: (
          <a
            href='https://github.com/PitchInteractiveInc/Phinch'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={phinch1} alt='phinch1' />
          </a>
        )
      },
    ],
  },
  {
    name: 'Slack',
    link: 'https://join.slack.com/t/phinch/shared_invite/enQtNjMwOTY4MDM5OTA4LTE5ZTcwMjYwNmFkNTJmNDdiNTg2OTdlYmNiNWFhMjg0MTYyNGEwZjY5NGQxZjBmZjVmMjlkMDYyM2RjZDkxYjQ',
    columns: [
      { type: 'logo', value: <img src={slack} alt='slack' /> },
      { type: 'Phinch2', value: (
          <a
            href='https://join.slack.com/t/phinch/shared_invite/enQtNjMwOTY4MDM5OTA4LTE5ZTcwMjYwNmFkNTJmNDdiNTg2OTdlYmNiNWFhMjg0MTYyNGEwZjY5NGQxZjBmZjVmMjlkMDYyM2RjZDkxYjQ'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={phinch2} alt='phinch2' />
          </a>
        )
      },
      { type: 'notes', value: <p>Community discussion and troubleshooting</p> },
      { type: 'Phinch1', value: '' },
    ],
  },
  {
    name: 'Google Groups',
    link: 'https://groups.google.com/forum/#!forum/phinch',
    columns: [
      { type: 'logo', value: <img src={googlegroups} alt='googlegroups' /> },
      { type: 'Phinch2', value: '' },
      { type: 'notes', value: '' },
      { type: 'Phinch1', value: (
          <a
            href='https://groups.google.com/forum/#!forum/phinch'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={phinch1} alt='phinch1' />
          </a>
        )
      },
    ],
  },
  {
    name: 'Phinch Blog',
    link: 'http://phinch.org/blog/',
    columns: [
      { type: 'logo', value: <img src={phinchblog} alt='phinchblog' /> },
      { type: 'Phinch2', value: '' },
      { type: 'notes', value: '' },
      { type: 'Phinch1', value: (
          <a
            href='http://phinch.org/blog/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={phinch1} alt='phinch1' />
          </a>
        )
      },
    ],
  }
];

export default function CommunityGrid() {
  const communityRows = communities.map(r => {
    const columns = r.columns.map(c => {
      const value = c.type === 'logo' ? (
        <a href={r.link} target='_blank' rel='noopener noreferrer'>{c.value}</a>
      ) : c.value;
      return (
        <div key={`${r.name.replace(/ /g, '-')}-${c.type}`} className={`community-cell type-${c.type}`}>
          {value}
        </div>
      );
    });
    return (
      <div className='community-row' key={r.name} title={r.name}>
        {columns}
      </div>
    );
  });
  return (
    <div className='community-grid'>
      {communityRows}
    </div>
  );
}
