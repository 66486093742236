import React from 'react';
import './style.css';

import loadVideo from '../videos/load-filter-data-screencast.mp4';
import overviewVideo from '../videos/overview-of-all-features-screencast.mp4';
import visualizationVideo from '../videos/main-visualization-features.mp4';
import exportVideo from '../videos/save-export-data-screencast.mp4';

const tutorials = [
  {
    id: 'load-filter-data-screencast',
    name: 'Load and Filter Data',
    video: loadVideo,
  },
  {
    id: 'overview-of-all-features-screencast',
    name: 'Overview of All Features',
    video: overviewVideo,
  },
  {
    id: 'main-visualization-features',
    name: 'Main Visualization Features',
    video: visualizationVideo,
  },
  {
    id: 'save-export-data-screencast',
    name: 'Save and Export',
    video: exportVideo,
  },
];

export default function Tutorials(props) {
  return (
    <div className='tutorials'>
      {
        tutorials.map(t => (
          <div key={`tutorial-${t.name.replace(/ /g, '-')}`} className='tutorial'>
            <video id={t.id} controls onPlay={props.onPlay}>
              <source src={t.video} type="video/mp4" alt={t.name} />
            </video>
            <div className='tutorial-label'>
              {t.name}
            </div>
          </div>
        ))
      }
    </div>
  );
}
