import React from 'react';

import Divider from '../Divider/';

import './style.css';

const datasets = [
  {
    heading: 'Project MERCCURI',
    subhead: 'Cell Phone and Shoes Microbiomes',
    block: [
      {
        number: '4.3 MB',
        label: 'Size',
      },
      {
        number: '2,185',
        label: 'Samples',
      },
      {
        number: '233',
        label: 'Observations',
      },
    ],
    detail: [
      "Are there differences between the microbes on people’s shoes on the East Coast versus the West Coast? Between basketball fan cell phones and football fan cell phones? This study collected 2500 swab samples at sporting events, space meetups, and other public events across the USA to answer these kinds of questions. Microbes were sampled from participants' cell phones and shoes from at these events.",
    ],
    link: {
      data: 'https://github.com/PhinchApp/datasets/raw/master/ps10.biomlike.2-edited-HDF5-metadata.biom',
      website: 'http://spacemicrobes.org/',
      article: 'https://peerj.com/articles/9235/',
    },
  },
  {
    heading: 'Showerhead Microbiome Project',
    subhead: '',
    block: [
      {
        number: '3.6 MB',
        label: 'Size',
      },
      {
        number: '652',
        label: 'Samples',
      },
      {
        number: '9,731',
        label: 'Observations',
      },
    ],
    detail: [
      "What do microbial communities on household showerheads look like around the world? The Showerhead Microbiome Project worked with citizen scientists to collect showerhead biofilm samples and associated water chemistry data from 656 households located across the United States and Europe. This recently published study found that water source, water chemistry, and household location influenced the prevalence of specific mycobacterial lineages detected in showerheads.",
    ],
    link: {
      data: 'http://phinch.org/showerhead_unoise_16S_otu_table_wTax_noChloroMito_nodust_nocntrls_2000%2Breads_March2018_vfinal-JSON-Metadata.txt',
      website: 'http://robdunnlab.com/projects/showerheads/',
      article: 'https://mbio.asm.org/content/9/5/e01614-18',
    },
  },
  {
    heading: 'Earth Microbiome Project',
    subhead: '',
    block: [
      {
        number: '123.9 MB',
        label: 'Size',
      },
      {
        number: '4,394',
        label: 'Samples',
      },
      {
        number: '157,780',
        label: 'Observations',
      },
    ],
    detail: [
      "The Earth Microbiome Project (EMP) is a massively collaborative effort to characterize microbial life on this planet, using DNA sequencing of crowd-sourced samples to understand patterns in microbial ecology across the biomes and habitats of our planet. The EMP is a comprehensive example of open science, leveraging a collaborative network of 500+ investigators, supporting pre-publication data sharing, and crowdsourcing data analysis to enable universal principles to be explored.",
    ],
    link: {
      data: 'https://github.com/PhinchApp/datasets/raw/master/emp_deblur_150bp.subset_10k.rare_5000-metadata.biom',
      website: 'http://earthmicrobiome.org/',
      article: 'https://www.nature.com/articles/nature24621',
    },
  },
];

export default function Datasets() {
  const blocks = datasets.map(d => (
    <div key={`dataset-block-${d.heading.replace(/ /g, '-')}`} className='section-third'>
      <div className="dataset-block">
        <div className="dataset-block-head">
          <div className="dataset-block-heading">
            {d.heading}
          </div>
          <div className="dataset-block-subhead">
            {d.subhead}
          </div>
        </div>
        <Divider mini />
        {
          d.block.map(b => (
            <div key={`dataset-${b.number}`} className="dataset-block-row">
              <div className="dataset-number">{b.number}</div>
              <div className="dataset-label">{b.label}</div>
            </div>
          ))
        }
        <a href={d.link.data} target='_blank' rel='noopener noreferrer' download>
          <button className="button data">
            Download Data
          </button>
        </a>
      </div>
    </div>
  ));

  const details = datasets.map(d => (
    <div key={`dataset-detail-${d.heading.replace(/ /g, '-')}`} className="section-third">
      <div className="dataset-info">
        <div className="dataset-head">
          <div className="dataset-heading">
            {d.heading}
          </div>
          <div className="dataset-subhead">
            {d.subhead}
          </div>
        </div>
        <div className="dataset-p-group">
        {
          d.detail.map((p, i) => <p key={`dataset-p-${i}`} className="dataset-p">{p}</p>)
        }
        </div>
      </div>
    </div>
  ));

  const links = datasets.map(d => (
    <div key={`dataset-links-${d.heading.replace(/ /g, '-')}`} className="section-third no-top">
      <a href={d.link.website} target='_blank' rel='noopener noreferrer'>
        <button className="button">
          Project Website
        </button>
      </a>
      {
        d.link.article ? (
          <a href={d.link.article} target='_blank' rel='noopener noreferrer'>
            <button className="button">
              Journal Article
            </button>
          </a>
        ) : null
      }
    </div>
  ));

  return (
    <div className='three-col'>
      {blocks}
      {details}
      {links}
    </div>
  );
}
