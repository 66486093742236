import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { debounce } from 'lodash';
import ReactGA from 'react-ga';

import './App.css';

import Microbes from './Microbes/';
import Divider from './Divider/';
import Progression from './Progression/';
import Datasets from './Datasets/';
import CommunityGrid from './CommunityGrid/';
import Tutorials from './Tutorials/';

import logo from './images/phinch.svg';
import facebook from './images/facebook.svg';
import twitter from './images/twitter.svg';
import alfredpsloan from './images/aps.png';
import overviewVideo from './videos/overview-of-all-features-screencast.mp4';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      microbeUpdate: 0,
      activeSection: 'Download',
    };

    this.sectionRefs = {};

    this.debouncedCheckSection = debounce(this.checkSection, 100).bind(this);
    this.debouncedResize = debounce(this.resize, 100).bind(this);
  }

  componentDidMount() {
    // Analytics
    ReactGA.initialize('UA-50346302-1');
    ReactGA.pageview(window.location.pathname);
    // Event Listeners
    window.addEventListener('scroll', this.debouncedCheckSection);
    window.addEventListener('resize', this.debouncedResize);
    // Scroll to Section
    const refKey = window.location.pathname.replace(/\//g, '');
    if (this.sectionRefs[refKey]) {
      this.initScroll = setTimeout(() => this.scrollTo(refKey), 150);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.initScroll);
    window.removeEventListener('scroll', this.debouncedCheckSection);
  }

  checkSection() {
    let activeSection = this.state.activeSection;
    const maxPageYOffset = document.body.offsetHeight - window.innerHeight;
    Object.keys(this.sectionRefs)
      .map(key => {
        const { offsetTop } = this.sectionRefs[key];
        const top = offsetTop > maxPageYOffset ? maxPageYOffset : offsetTop;
        return { key, top };
      })
      .sort((a, b) => a < b)
      .forEach(s => {
        if (window.pageYOffset >= s.top) {
          activeSection = s.key;
        }
      });
    if (activeSection !== this.state.activeSection) {
      const microbeUpdate = this.state.microbeUpdate + 1;
      window.history.replaceState({}, activeSection, `/${activeSection}`);
      ReactGA.pageview(`/${activeSection}`);
      this.setState({ activeSection, microbeUpdate });
    }
  }

  scrollTo(refKey) {
    window.scrollTo({
      behavior: 'smooth',
      top: this.sectionRefs[refKey].offsetTop + 2,
      left: 0,
    });
  }

  resize() {
    const microbeUpdate = this.state.microbeUpdate + 1;
    this.setState({ microbeUpdate });
  }

  videoPlay(event) {
    const { currentTarget } = event;
    const { id } = currentTarget;
    ReactGA.event({
      category: 'video',
      action: 'play',
      label: id,
    });
  }

  render() {
    const navSections = [ 'Download', 'How to Get Started', 'Flagship Datasets', 'Community', 'About', 'Tutorials' ];
    const navLinks = navSections.map(n => {
      const navKey = n.replace(/ /g, '-');
      const className = `link ${this.state.activeSection === navKey ? 'active' : ''}`;
      return (
        <button
          key={navKey}
          className={className}
          onClick={() => this.scrollTo(navKey)}
        >
          {n}
        </button>
      );
    });
    return (
      <div className="App">
        <Microbes
          show={true}
          width={window.innerWidth}
          height={window.innerHeight}
          count={500}
          update={this.state.microbeUpdate}
          background="#191919"
        />
        <header className="header">
          <div className="logo-container" onClick={() => this.scrollTo('How-to-Get-Started')}>
            <img src={logo} className="logo" alt="Phinch logo" />
          </div>
          <div className="link-container">
            {navLinks}
          </div>
          <div className="social-container">
            <div className="social">
              <a
                target='_blank'
                rel="noopener noreferrer"
                href='https://www.facebook.com/sharer.php?u=http://phinch.org'
              >
                <img src={facebook} className="logo" alt="facebook" />
              </a>
            </div>
            <div className="social">
              <a
                target='_blank'
                rel="noopener noreferrer"
                href={encodeURI('https://twitter.com/intent/tweet?text=Phinch is a data visualization framework aimed at promoting novel explorations of  biological datasets.&url=http://phinch.org')}
              >
                <img src={twitter} className="logo" alt="twitter" />
              </a>
            </div>
          </div>
        </header>
        <div className='content'>
          <div className='column'>

            <div className='section' ref={r => this.sectionRefs['Download'] = r}>
              <div className='section-heading'>
                Enhance Insights with the new Phinch App
              </div>
              <Divider />
              <div className='section-main'>
                <p className='section-p'>
                  Phinch is a data visualization framework aimed at promoting novel explorations of large biological datasets (microbiomes, metagenomes, etc.). Phinch 2.0 has now been relaunched as a standalone desktop application, compatible with macOS, Windows, and Linux operating systems. New features include support for HDF5 and JSON BIOM files, retooled visualizations, improved image export and sharing, and the ability to work offline.
                </p>
                <div className='segment-center'>
                  <div style={{ margin: '0 18em' }}>
                    <a href='https://github.com/PhinchApp/Phinch/releases' target='_blank' rel='noopener noreferrer'>
                      <div className='circle-logo'>
                        <img src={logo} className="logo" alt="Phinch logo" />
                      </div>
                      <button className='button download'>Download v2.0.1</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='section' ref={r => this.sectionRefs['How-to-Get-Started'] = r}>
              <div className='section-heading'>
                How to Get Started with Phinch
              </div>
              <Divider />
              <div className='section-main'>
                <div className='segment-center-video'>
                  <video
                    id='overview-of-all-features-screencast'
                    controls={true}
                    width='100%'
                    height='100%'
                    type="video/mp4"
                    onPlay={this.videoPlay}
                  >
                    <source src={overviewVideo} type="video/mp4" alt="phinch overview" />
                  </video>
                </div>
                <Progression />
              </div>
            </div>

            <div className='section' ref={r => this.sectionRefs['Flagship-Datasets'] = r}>
              <div className='section-heading'>
                Flagship Datasets
              </div>
              <Divider />
              <Datasets />
            </div>
            <div className='section' ref={r => this.sectionRefs['Community'] = r} style={{ position: 'relative' }}>
              <div className='section-heading'>
                Phinch Community
              </div>
              <Divider />
              <div className='section-main'>
                <p className='section-p'>
                  Please use the following Phinch community resources for bug reporting and software code/documentation (GitHub), as well as community discussion and troubleshooting (Slack). Links to Phinch v1 resources are no longer actively maintained, and provided for historical purposes only.
                </p>
              </div>
              <CommunityGrid />
            </div>
            <div className='section' ref={r => this.sectionRefs['About'] = r}>
              <div className='section-heading'>
                About Phinch
              </div>
              <Divider />
              <div className='section-main'>
                <p className='section-p'>
                  Phinch is an open-source framework for visualizing biological data, funded by a grant from the Alfred P. Sloan foundation. This project represents an interdisciplinary collaboration between Pitch Interactive (a data visualization studio in Oakland, CA; <a target='_blank' rel='noopener noreferrer' href='https://pitchinteractive.com/'>https://pitchinteractive.com</a>) and biological researchers at UC Riverside (Principal Investigator: Dr. Holly Bik, <a target='_blank' rel='noopener noreferrer' href='https://www.biklab.org/'>https://www.biklab.org</a>).
                </p>
                <div className='segment-center' style={{ margin: '3rem 18em' }}>
                  <img src={alfredpsloan} alt="Alfred P. Sloan Foundation" />
                </div>
                <p className='section-p'>
                  Whether it's genes, proteins, or microbial species, Phinch provides an interactive visualization tool that allows users to explore and manipulate large biological datasets. Computer algorithms face significant difficulty in identifying simple data patterns, and writing algorithms to tease out complex, subtle relationships (the type that exist in biological systems) is almost impossible. However, the human eye is adept at spotting visual patterns, able to quickly notice trends and outliers. It is this philosophy that has driven the development of intuitive, well-designed software tools and user interfaces such as the Phinch framework.
                </p>
                <p className='section-p'>
                  Scientific visualization represents an innovative method towards tackling the current bottleneck in bioinformatics data analysis. In addition to giving researchers a unique approach for exploring large datasets, it stands to empower biologists with the ability to conduct powerful analyses and export publication-quality graphics, without requiring a deep level of computational knowledge.
                </p>
              </div>
              <div className='sub-section'>
                <div className='section-heading'>
                  Cite Phinch
                </div>
                <div className='section-main'>
                  <p className='section-p'>
                    Please cite the Phinch framework as follows:
                  </p>
                  <p className='section-p'>
                    Phinch: An interactive, exploratory data visualization framework for –Omic datasets
                  </p>
                  <p className='section-p'>
                    Holly M Bik, Pitch Interactive. bioRxiv 009944; <a href="https://doi.org/10.1101/009944" target="_blank" rel='noopener noreferrer'>https://doi.org/10.1101/009944</a>
                  </p>
                </div>
              </div>
            </div>
            <div className='section'>
              <div className='section-heading'>
                Features
              </div>
              <Divider />
              <div className='section-main'>
                <p className='section-p'>
                  The Phinch 2.0 Desktop App has been relaunched with a variety of new and improved features, including:
                </p>
                <ul className='section-p'>
                  <li>Standalone Electron desktop app that does not require an internet connection for file upload and visualization.</li>
                  <li>Local storage of data files and visualization choices (auto-saved on a user’s computer within the Phinch App)</li>
                  <li>Support for BIOM 1.0 (JSON format) and 2.0 (HDF5 format) files - Phinch is now fully compatible with all observation tables outputs from the QIIME1 and QIIME2 pipelines. Reliance on the BIOM format (Biological Observation Matrix; <a target='_blank' rel='noopener noreferrer' href='http://biom-format.org/'>http://biom-format.org</a>) means that Phinch is broadly compatible with any type of biological data that can be represented as a tab-delimited dataset with associated sample/observation metadata.</li>
                  <li>Improved visualization speed and software performance, including support for much larger file sizes in Phinch 2.0</li>
                  <li>Improved file checking and error reporting, including a new file validation step upon data upload</li>
                  <li>Streamlined “parser window” that allows for rapid data sorting and filtering based on the associated metadata. Samples or metadata categories can be used to remove unwanted data, using slider bars and checkboxes in the parser window.</li>
                  <li>Condensed visualization window that brings together a variety of tools and features into a single interface: auto-complete search box for finding specific taxonomy/ontologies present in the dataset, “subway chart” for viewing different levels of taxonomy/ontology hierarchies, drop down “Attributes” menu to summarize dataset according to different metadata categories, hover boxes that provide information regarding dataset fractions and observations (e.g. taxonomy associated with an OTU), custom colorized tagging of samples, new sidebar features that pulls out information on a single taxon (abundance information and slider bar filtering capabilities)</li>
                  <li>Improved “Export” feature that enables rapid download of publication-quality SVG images of Phinch visualizations</li>
                </ul>
              </div>
            </div>
            <div className='section' ref={r => this.sectionRefs['Tutorials'] = r}>
              <div className='section-heading'>
                Tutorials
              </div>
              <Divider />
              <div className='section-main'>
                <Tutorials onPlay={this.videoPlay} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function App() {
  return (
    <Router>
      <Route path="*" component={Main} />
    </Router>
  )
}

export default App;
