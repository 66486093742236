import React from 'react';
import './style.css';

export default function Divider(props) {
  const segments = [
    { width: (310 / 720) * 100, color: props.mini ? '#ffffff' : '#4c4c4c' },
    { width: (172 / 720) * 100, color: '#664be8' },
    { width: (112 / 720) * 100, color: '#00da3e' },
    { width: (50 / 720) * 100, color: '#919396' },
    { width: (36 / 720) * 100, color: '#0065fb' },
    { width: (30 / 720) * 100, color: '#ff2514' },
    { width: (10 / 720) * 100, color: '#f085b2' },
  ];
  const className = props.mini ? 'divider mini' : 'divider';
  return (
    <div className={className}>
      {
        segments.map(s => {
          return <div key={s.color} className='segment' style={{ width: `${s.width}%`, background: s.color }} />
        })
      }
    </div>
  );
}
