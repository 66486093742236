import React from 'react';
import './style.css';

const segments = [
  { label: 'Download Phinch', color: '#000000' },
  { label: 'Download Data', color: '#333333' },
  { label: 'Get Started', color: '#666666' },
];

export default function Progression() {
  return (
    <div className='progression'>
      {
        segments.map((s, i) => {
          const showArrow = i === (segments.length - 1) ? '' : 'show-arrow';
          return (
            <div
              key={`p-${i}`}
              className={`progression-arrow ${showArrow}`}
              style={{ width: `${s.width}%`, background: s.color }}
            >
              <div className='progression-container'>
                <div className='progression-number'>{i + 1}</div>
                <div className='progression-label'>{s.label}</div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}
