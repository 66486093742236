import React, { Component } from 'react';

const metrics = {
  duration: 2000,
  length: {
    min: -24,
    max: 48,
  },
  width: {
    min: 2,
    max: 4,
  },
}

function getRandomMicrobeOffset(multiplier = 1) {
  return Math.floor((metrics.length.min + Math.floor(Math.random() * metrics.length.max)) * multiplier);
}

function getRandomMicrobePosition(width, height) {
  const headerHeight = ((16 / 1080) * width) * 5;
  const gutterWidth = (width * 0.5) / 2;
  const y = headerHeight + Math.floor(Math.random() * (height - headerHeight));
  let x = Math.floor(Math.random() * width);
  if (y > headerHeight * 2) {
    if (x < width / 2) {
      x = Math.floor(Math.random() * gutterWidth);
    } else {
      x = Math.floor((width - gutterWidth) + Math.random() * gutterWidth);  
    }
  }
  return { x, y };
}

export default class Microbes extends Component {
  constructor(props) {
    super(props);
    this.completed = 0;
    this.requestMajorNext = false;
    this.updateCanvas = this.updateCanvas.bind(this);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext('2d');
    this.microbes = this.createMicrobes();
    this.scheduleUpdate();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.requestMajorNext = true;
    }
  }

  createMicrobes() {
    let microbes = this.microbes ? this.microbes : [];
    if (this.props.count < microbes.length) {
      microbes = microbes.slice(0, this.props.count);
    }
    let index = 0;
    while (microbes.length < this.props.count) {
      const stroke = {
        r: Math.floor(Math.random() * 255),
        g: Math.floor(Math.random() * 255),
        b: Math.floor(Math.random() * 255),
        a: 0.25,
      };
      const w = metrics.width.min + Math.floor(Math.random() * metrics.width.max);
      const size = {
        x: getRandomMicrobeOffset(1),
        y: getRandomMicrobeOffset(1),
      };
      const start1 = getRandomMicrobePosition(this.props.width, this.props.height);
      const end1 = getRandomMicrobePosition(this.props.width, this.props.height);
      microbes.push({
        index,
        progress: 0,
        time: {
          start: Date.now(),
          current: Date.now(),
        },
        offset: (index / this.props.count) * -metrics.duration,
        start: {
          x1: start1.x,
          y1: start1.y,
          x2: size.x,
          y2: size.y,
          w,
          stroke,
        },
        end: {
          x1: end1.x,
          y1: end1.y,
          x2: size.x,
          y2: size.y,
          w,
          stroke,
        },
      });
      index += 1;
    }
    return microbes;
  }

  updateMicrobe(m, isMajor) {
    m.progress = 0;
    m.time = {
      start: Date.now(),
      current: Date.now(),
    };
    m.offset = (m.index / this.microbes.length) * -metrics.duration;
    m.start = Object.assign({}, m.end);
    m.end.w = metrics.width.min + Math.floor(Math.random() * metrics.width.max);
    m.end.stroke = {
      r: Math.floor(Math.random() * 255),
      g: Math.floor(Math.random() * 255),
      b: Math.floor(Math.random() * 255),
      a: 0.25,
    };
    const multiplier = isMajor ? ((this.props.width / metrics.length.max) / 2) : 0.5;
    m.end.x1 = m.start.x1 + getRandomMicrobeOffset(multiplier);
    m.end.y1 = m.start.y1 + getRandomMicrobeOffset(multiplier);
    if (
      m.end.x1 < metrics.length.min || m.end.x1 > this.props.width + metrics.length.min * -1
        ||
      m.end.y1 < metrics.length.min || m.end.y1 > this.props.height + metrics.length.min * -1
    ) {
      const { x, y } = getRandomMicrobePosition(this.props.width, this.props.height);
      m.end.x1 = x;
      m.end.y1 = y;
    }
    m.end.x2 = getRandomMicrobeOffset(1);
    m.end.y2 = getRandomMicrobeOffset(1);
    return m;
  }

  scheduleUpdate() {
    window.requestAnimationFrame(this.updateCanvas);
  }

  easeInOutQuart(t) { 
    return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t
  }

  tween(start, end, progress) {
    return start + ((end - start) * progress);
  }

  updateCanvas() {
    this.ctx.fillStyle = this.props.background;
    this.ctx.fillRect(0, 0, this.props.width, this.props.height);
    if (this.props.show) {
      this.ctx.lineCap = 'round';
      this.microbes = this.microbes.map((m, i) => {
        const time = Math.min(1, Math.max(0, (m.time.current - m.time.start + m.offset)) / metrics.duration);
        m.progress = this.easeInOutQuart(time);
        const stroke = {
          r: this.tween(m.start.stroke.r, m.end.stroke.r, m.progress),
          g: this.tween(m.start.stroke.g, m.end.stroke.g, m.progress),
          b: this.tween(m.start.stroke.b, m.end.stroke.b, m.progress),
          a: this.tween(m.start.stroke.a, m.end.stroke.a, m.progress),
        }
        this.ctx.strokeStyle = `rgba(${stroke.r}, ${stroke.g}, ${stroke.b}, ${stroke.a})`;
        this.ctx.beginPath();
        this.ctx.lineWidth = this.tween(m.start.w, m.end.w, m.progress);
        const current = {
          x: this.tween(m.start.x1, m.end.x1, m.progress),
          y: this.tween(m.start.y1, m.end.y1, m.progress),
        };
        this.ctx.moveTo(
          current.x,
          current.y
        );
        const size = {
          x: this.tween(m.start.x2, m.end.x2, m.progress),
          y: this.tween(m.start.y2, m.end.y2, m.progress),
        }
        this.ctx.lineTo(
          current.x + size.x,
          current.y + size.y
        );
        this.ctx.stroke();
        if (m.progress > .99) {
          this.completed++;
          m = this.updateMicrobe(m, this.requestMajorNext);
        } else {
          m.time.current = Date.now();
        }
        return m;
      });
      if (this.completed >= this.microbes.length - 1) {
        this.completed = 0;
        this.requestMajorNext = false;
      }
      this.scheduleUpdate();
    }
  }

  render() {
    return (
      <canvas
        ref={c => { this.canvas = c; }}
        width={this.props.width}
        height={this.props.height}
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 0,
          padding: 0,
          outline: 'none',
          border: 'none',
          overflow: 'hidden',
          verticalAlign: 'middle',
        }}
      />
    );
  }
}
